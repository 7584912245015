import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { ConfirmDelete, Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { VideoModel } from "@/models";
import { Common } from "../Common";

export class SearchForm {
  title = '';
}

type response = { [key: string]: unknown };

@Component<AdminVideosIndexController>({
  components: {
    SideNav, ConfirmDelete, Lightbox
  }
})
export default class AdminVideosIndexController extends Vue {
  private doubleClick = false;
  private searchForm = new SearchForm();
  private videoList: object = {};
  private num = 0;
  private pageHtml = "";
  private vid = 0;
  private token = window.localStorage.getItem('adminToken') as string;

  private errorMsg = "";
  private openLB = false;
  private errNo!: number;

  public created() {
    this.getVideoList();
  }

  private async getVideoList() {
    const item: { [key: string]: string } = {
      page: this.$route.query.page as string,
      title: this.searchForm.title,
      token: this.token,
    }
    const data = await VideoModel.getList(item) as response;
    Common.checkToken(data.ERR_CODE as number);
    this.num = data.num as number;
    this.videoList = data.list as object;
    this.pageHtml = data.pageHtml as string;
  }

  private delVideo(id: number) {
    this.$emit("openConfirmDelete", true);
    this.vid = id;
  }

  private async deleteVideo() {
    if (!this.doubleClick) {
      this.doubleClick = true;

      const item = {
        vid: this.vid,
        token: this.token,
      }
      this.errNo = await VideoModel.del(item);
      this.$emit("closeConfirmDelete", false);
      Common.checkToken(this.errNo);
      this.errorMsg = ErrorMessage[this.errNo];
      this.openLB = true;
      this.doubleClick = false;
    }
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.go(0);
    }
  }
}
